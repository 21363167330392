.dropdown {
    position: relative;
    display: inline-block;
}

.dropdown ul {
    position: absolute;
	visibility: collapse;
	height: 0;
    opacity: 0;
    
    list-style-type: none;
    margin: 0;
    padding: 0;
    top: 45px;
    right: 0px;
    width: 200px;
    background-color: white;
    font-weight: bold;
    left: 0 !important;
    box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
    z-index: 1;
}

.dropdown-arrow-up {
	margin: -6px 0 0 4px;
  width: 0;
  height: 0;
  border-left: 6px solid transparent;
  border-right: 6px solid transparent;
  border-bottom: 6px solid #fff;
}

.dropdown-show ul {
    visibility: visible;
	animation-name: fadeInCollapse;
	animation-duration: 0.6s;
	animation-delay: 0.1s;
	animation-fill-mode: forwards;
 }

.dropdown li span {
   cursor: pointer;
}

.dropdown li {
    padding: 8px 16px !important;
    margin: 0 !important;
    border-bottom: 1px solid #e5e5e5;
}

.dropdown li:last-child {
    border-bottom: none;
}

.dropdown li:hover {
    background-color: #e5e5e5;
    color: white;
}

.dropdown .button {
    cursor: pointer;
    padding: 8px;
    color: white;
}

.dropdown .button:before {
    content: "";
}

@keyframes fadeInCollapse {
	0% {
		display:none;
		opacity: 0;
	}
	1% {
		display: block;
		opacity: 0;
		height: auto;
	}
	100% {
		display: block ;
		opacity: 1;
		height: auto;
	}
}

@keyframes fadeOutCollapse {
	0% {
		display: block;
		opacity: 1;
	}
	99% {
		opacity: 0;
	}
	100% {
		display: none;
		opacity: 0;
		height: 0;
		margin: 0;
	}
}